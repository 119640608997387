@tailwind base;
@tailwind components;
@tailwind utilities;


body{
    font-family: 'IBM Plex Sans Condensed', sans-serif !important;
    font-family: 'Lato', sans-serif !important;
    font-family: 'Montserrat Alternates', sans-serif !important;
    font-family: 'Poppins', sans-serif !important;
    /* font-family: 'Roboto', sans-serif; */
}

.border__Top{
    border-top: solid #1b9bf0 3px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}


.herox{
    position: relative;
    background-image: url("https://www.csoonline.com/wp-content/uploads/2023/07/data-center-control-it-specialists-network-monitoring_shutterstock_669170671.jpg?quality=50&strip=all");
    background-size: cover;
    background-position: center;
    height: fit-content; 
  }
  
  .herox::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
  }
  .herox::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%; /* Adjust the height as needed */
    background: linear-gradient(to bottom, rgba(15, 20, 25, 0) 0%, rgba(15, 20, 25, 0.6) 100%);
  }
  
  .shadow__box{
    border-bottom: 1px solid #007FFF;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(6, 141, 232, 0.5);    ;
  }


  .border__b{
    border: #1b9bf0 solid 1px;
    background: rgba(0, 127, 255, 0.2);
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 127, 255, 1);
  }

  .numbers{
    position: relative;
    background-image: url("https://www.intel.com/content/dam/www/central-libraries/us/en/images/2022-06/adobestock-472215790.jpg");
    background-size: cover;
    background-position: center;
    height: fit-content; 
  }
  
  .numbers::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
  }

  .Button__{
    border: #007FFF solid 1px;
    background: rgba(0, 127, 255, 0.2);
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 127, 255, 1);
    padding: 9px;
    margin-top: 10px;
    color: white;
    transition: 300ms ease-in-out;
 }
   


.hero {
  position: relative;
  background-image: url('https://imageio.forbes.com/specials-images/imageserve/5e2ded52a854780006af8e39/Top-10-Cybersecurity-Companies-To-Watch-In-2020-image/960x0.jpg?format=jpg&width=960');
  background-size: cover;
  background-position: center;
  height: 280px; 
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
}


.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1; 
}

.hero-content h2 {
  font-size: 2rem;
  font-weight: bold;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.contact-btn{
  background-color: #1b9bf0 ;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}




.Bitcoin {
  position: relative;
  background-image: url("https://www.bankrate.com/2021/10/22132908/Investing-in-cryptocurrency-vs.-traditional-stocks.jpeg");
  background-size: cover;
  background-position: center;
  height: fit-content;
  background-color: rgba(0, 49, 132, 0.6);
}


.Bitcoin::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Add a gradient overlay for the bottom part */
.Bitcoin::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%; /* Adjust the height as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}


.Shadow__x{
  background-color: #16181c;
  box-shadow: 0 2px 8px rgba(0, 127, 255, 1);
  border-radius: 19px;
  border-bottom: 1px solid #007FFF;
  height: 100%;
}

.romance{
  position: relative;
  background-image: url("https://cyberscoop.com/wp-content/uploads/sites/3/2023/07/GettyImages-1258231373.jpg");
  background-size: cover;
  background-position: center;
  height: fit-content;
  background-color: rgba(0, 49, 132, 0.6);
}


.romance::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Add a gradient overlay for the bottom part */
.romnace::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%; /* Adjust the height as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}



.Passwords{
  position: relative;
  background-image: url("https://www.securitymagazine.com/ext/resources/Default-images/Responsive-Defaults/password1-900px.jpg?1472739459");
  background-size: cover;
  background-position: center;
  height: fit-content;
  background-color: rgba(0, 49, 132, 0.6);
}


.Passwords::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Add a gradient overlay for the bottom part */
.Passwords::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%; /* Adjust the height as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}


.Rug{
  position: relative;
  background-image: url("https://img.freepik.com/premium-photo/smiling-young-asian-woman-glasses-typing-tablet-has-romantic-chat-with-hearts-neon-background_116547-50310.jpg");
  background-size: cover;
  background-position: center;
  height: fit-content;
  background-color: rgba(0, 49, 132, 0.6);
}


.Rug::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Add a gradient overlay for the bottom part */
.Rug::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%; /* Adjust the height as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}

.Drainer{
  position: relative;
  background-image: url("https://www.eezlaw.com/images/blog/shutterstock_701124811.jpg");
  background-size: cover;
  background-position: center;
  height: fit-content;
  background-color: rgba(0, 49, 132, 0.6);
}


.Drainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Add a gradient overlay for the bottom part */
.Drainer::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%; /* Adjust the height as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}


.About{
  position: relative;
  background-image: url("https://images.pexels.com/photos/3688761/pexels-photo-3688761.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-size: cover;
  background-position: center;
  height: fit-content;
  background-color: rgba(0, 49, 132, 0.6);
}


.About::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Add a gradient overlay for the bottom part */
.About::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%; /* Adjust the height as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}


.Contact{
  position: relative;
  background-image: url("https://media.istockphoto.com/id/889458208/photo/youve-reached-our-support-line.jpg?s=612x612&w=0&k=20&c=-9-M2o4rmo5hgnu3shIaARICeVlCkrorsWu2sy6H_50=");
  background-size: cover;
  background-position: center;
  height: fit-content;
  background-color: rgba(0, 49, 132, 0.6);
}


.Contact::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Add a gradient overlay for the bottom part */
.Contact::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%; /* Adjust the height as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}

.button-57{
  background-color: #007FFF;
  color: white;
  padding: 10px;
  border-radius: 10px;
}

.button-58{
  background-color: white;
  color: #007FFF;
  padding: 10px;
  border-radius: 10px;
  border: #007FFF solid 1px;
}

